.npm__react-simple-code-editor__textarea {
  outline: 0 !important;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.awaken__transaction-row td {
  padding: 10px 25px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.rechart-light .recharts-rectangle {
  /* the current fill color but 0.8 opacity */
  fill: #f3f4f5 !important;
}

.rechart-dark .recharts-rectangle {
  /* the current fill color but 0.8 opacity */
  fill: #2b2d2e !important;
}

.recharts-tooltip-wrapper {
  background-color: transparent !important;
}

.awaken__switch_dark .chakra-switch__track {
  background: #43484d;
}

.gradient-text {
  background: -webkit-linear-gradient(
      45deg,
      rgba(0, 209, 255, 0.5) 0%,
      rgba(0, 255, 240, 0) 100%
    ),
    #00e894;
  color: #20bdff;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  border-width: 0px !important;
  background: -webkit-linear-gradient(
      45deg,
      rgba(0, 209, 255, 0.5) 0%,
      rgba(0, 255, 240, 0) 100%
    ),
    #00e894;
  box-shadow: 0px 0px 4px 1px rgba(0, 255, 209, 0.5);
  color: #ffffff;
}

#root {
  width: 100%;
}

html {
  overflow-x: hidden;
}

.glow {
  box-shadow: 0px 0px 20px rgba(0, 255, 209, 0.5);
}

.token-submit-btn {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.wallet-adapter-modal-wrapper {
  border: 1px solid #252525 !important;
  box-shadow: 0px 4px 30px rgba(105, 105, 105, 0.3) !important;
  background-color: #0e0e0e !important;
  padding: 15px !important;
}

.wallet-adapter-modal-list .wallet-adapter-button:not([disabled]):hover {
  background-color: #252525;
}

.wallet-adapter-button-start-icon {
  display: none !important;
}

.wallet-adapter-dropdown-list {
  background-color: #0e0e0e !important;
  border: 1px solid #252525 !important;
  box-shadow: 0px 4px 30px rgba(105, 105, 105, 0.3) !important;
}

.wallet-adapter-dropdown-list
  .wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #252525;
}

.wallet-adapter-modal {
  z-index: 100000 !important;
}

.accrue__link:hover {
  color: #00e894;
}

.tickLabel {
  fill: #b1b1b1 !important;
}

.domainAndTicks > .tick > line,
.domain {
  stroke: #252525 !important;
}

/* pagination styling */
.page-link {
  position: relative;
  display: block;
  color: #000 !important;
  padding: 0.25rem 0.5rem !important;
  /* width: 2rem; */
  text-decoration: none;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dark-page-link {
  background-color: #01030b;
  position: relative;
  display: block;
  color: #fff !important;
  padding: 0.25rem 0.5rem !important;
  /* width: 2rem; */
  text-decoration: none;
  font-size: 14px;
  border: 1px solid #43484d;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  /* box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25); */
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #43484d;
  border-color: #43484d;
}

.dark-page-item.active .dark-page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #43484d !important;
  border-color: #43484d;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.tags-entry-selector__multi-value {
  min-width: inherit !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.progress-border {
  --border-size: 0.2rem;
  border: var(--border-size) dotted transparent;
  background-image: linear-gradient(
      to right,
      rgb(255 255 255 / var(--opacity)),
      rgb(255 255 255 / var(--opacity))
    ),
    conic-gradient(
      from var(--angle),
      #d3d6d8 0deg 90deg,
      #e1e3e6 90deg 180deg,
      #d3d6d8 180deg 270deg,
      #e1e3e6 270deg 360deg
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* not used because doesn't support border radius */
.progress-border-2 {
  --border-size: 0.12rem;
  border: var(--border-size) dotted transparent;
  border-radius: 5px;
  border-image: conic-gradient(
      from var(--angle),
      #a6acb1 0deg 90deg,
      #d5d6d8 90deg 180deg,
      #a6acb1 180deg 270deg,
      #d5d6d8 270deg 360deg
    )
    1 stretch;
  background: rgb(255 255 255 / var(--opacity));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.progress-border-yellow {
  --border-size: 0.12rem;
  border: var(--border-size) dotted transparent;
  border-radius: 5px;
  border-image: conic-gradient(
      from var(--angle),
      #ffbb00 0deg 90deg,
      #ffdd33 90deg 180deg,
      #ffbb00 180deg 270deg,
      #ffdd33 270deg 360deg
    )
    1 stretch;
  background: rgb(255 255 255 / var(--opacity));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.progress-border-2-thick {
  --border-size: 0.25rem;
  border: var(--border-size) dotted transparent;
  border-radius: 5px;
  border-image: conic-gradient(
      from var(--angle),
      #346bf9 0deg 90deg,
      #d5d6d8 90deg 180deg,
      #346bf9 180deg 270deg,
      #d5d6d8 270deg 360deg
    )
    1 stretch;
  background: rgb(255 255 255 / var(--opacity));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .progress-border {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }

  .progress-border-2 {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }

  .progress-border-yellow {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }

  .progress-border-2-thick {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }
}

#chakra-toast-manager-top {
  top: 3px !important;
}

.waving:before {
  content: attr(data-hover);
}

.waving {
  animation-name: wave-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

table {
  border: 1px solid #ededed;
  border-radius: 5px;
}

.awaken__progress_bar > div {
  background: rgb(0, 200, 5);
}

.awaken__progress_bar_blue > div {
  background: rgb(0, 200, 5);
}

.awaken__progress_bar_red > div {
  background: #f20d33;
}

.awaken__progress_bar_green > div {
  background: #00e894;
}

.glow {
  border-radius: 5px;
  border: 2px solid rgb(255, 236, 23);
  box-shadow:
  /* 0 0 1px 1px #fff,   */
  /* 0 0 3px 2px #dd99ff, */ 0 0 4px 2px
    rgb(255, 236, 2, 0.4);
}

.referral-glow {
  border-radius: 5px;
  box-shadow: 0 0 1px 1px #fff, 0 0 5px 3px #00e894, 0 0 15px 6px #99ccff;
}

.awaken__progress_active_color > div {
  background-color: #346bf9 !important; /*#d6d3d1*/
}

.react-datepicker__input-container > input {
  padding: 8px 12px !important;
  font-size: 16px !important;
}

.tv-lightweight-charts table {
  border: none;
}

.tv-lightweight-chart
  .tv-widget-content
  .tvchart__grid
  .tvchart__grid-row
  .tvchart__grid-line {
  top: -1px; /* Adjust the position to move the grid line to the top */
  bottom: auto; /* Disable the default bottom positioning */
}

/*  if it is checked data-checked */
.asset-breakdown-switch span:checked {
  background-color: pink;
}

.collapsed-borders th,
.collapsed-borders td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-datepicker__input-container .awaken__asset-details-date-input {
  font-size: 14px !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.free-banner {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 0;
}

.free-text {
  animation: scrollLeft 1s linear infinite; /* Adjust timing as needed */
  padding: 5px 10px; /* Adjust padding as needed */
  display: inline-block; /* This will treat each 'FREE' as an individual block */
}

.datepicker-darkmode {
  background-color: #01030b !important;
  border: 1px solid #2b2d2e !important;
  color: #fff !important;
}

@font-face {
  font-family: "Mona Sans";
  src: url("./fonts/mona/Mona-Sans.woff2") format("woff2");
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
td,
tr,
th {
  font-family: "Mona Sans", sans-serif !important;
}

@keyframes beat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes beat-icon {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.gradient-text-welcome {
  background: linear-gradient(
    30deg,
    var(--green60) 0%,
    var(--lightBlue50) 50%,
    var(--green70) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* for non-webkit browsers */
  color: transparent; /* fallback for browsers that don't support background-clip */
  display: inline-block; /* ensures the gradient is applied to inline elements */
}

@keyframes pulse-small {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-big {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
